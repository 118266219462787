import React, { useState, useEffect } from 'react';
import axios from "axios";
//https://react-data-table-component.netlify.app/?path=/docs/getting-started-examples--docs
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';

export default function Whitelist() {
    const [data, setData] = useState([]);
    const [accountid, setAccountid] = useState();

    const fetchwhitelist = async () => {
        const response = await axios.get(`https://interceptor.skylab.nl:2807/whitelist`);
        setData(response.data);
    };

    async function handleAccountAction(accountAction) {
      //Get accountid from local storage
      //console.log(accountid, accountAction)

      return fetch('https://interceptor.skylab.nl:2807/accountaction', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ accountid: accountid, action: accountAction })
      })
          .then(function (response) {

              return response.text();
          })
          .catch(function (error) {
              console.error(error);
          })
  };

    async function deleteWhiteMac(e, whitelistid) {
      e.preventDefault();
      return fetch('https://interceptor.skylab.nl:2807/blacklist', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ whitelistid: whitelistid})
      })
          .then(function (response) {

                fetchwhitelist();
                handleAccountAction('Delete Whitelistid:'+ whitelistid)

              return response.text();
          })
          .catch(function (error) {
              console.error(error);
          })
  };

const columns_whitelist=[
{
    name:'mac',
    selector: row => row.whitemac
},
{
  name:'name',
  selector: row => row.name
},
{
  name: 'id',
  selector: row => row.whitelistid,
  width: '0',
},
{
  button: 'true',
  cell: (row) => (
    <Button variant="warning"
      onClick={(e) => {
        const confirmBox = window.confirm(
          "Do you really want to delete this mac?"
        )
        if (confirmBox === true) {
          deleteWhiteMac(e,row.whitelistid)
        }
      }}>
      Delete
    </Button>
  )
}
];
    useEffect(() => {
        fetchwhitelist();
        const items = JSON.parse(localStorage.getItem('token'));
        if (items) {
          setAccountid(items.id);
        }
    }, []);

    return (
        <>
            <div className='cTable'>
                <DataTable
                    columns={columns_whitelist}
                    data={data}
                ></DataTable>
            </div>
        </>
    );
}