import React from 'react';
import './App.css';
import Dashboard from './dashboard';
import Login from './login';
import useToken from './useToken';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

 const { token, setToken } = useToken();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <div className="wrapper">
            <Dashboard />
    </div>
  );
}

export default App;