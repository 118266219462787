import React, { useState, useEffect, useRef} from 'react';
import axios from "axios";
//https://react-data-table-component.netlify.app/?path=/docs/getting-started-examples--docs
import DataTable from 'react-data-table-component';

//https://react-bootstrap.netlify.app/docs/forms/input-group
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import './account.css';

export default function Accountpassword() {
    const [data, setData] = useState([]);
    const [IsChangePassword, setIsChangePassword] = useState(false);
    const [accountid, setAccountid] = useState();

    const newpasswordRef = useRef('');

    const columns_Accounts = [
        {
            name: 'Accountname',
            selector: row => row.accountname,
            sortable: true,
            Alignment: 'left',
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            Alignment: 'left',
        },
        {
            name: 'Pushsaver',
            selector: row => row.pushsaver,
            sortable: true,
            Alignment: 'left',
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
            Alignment: 'left',
        },
        {
            name: 'In group(s)',
            selector: row => row.usergroupid.toString(),
            sortable: true,
            center: 'true',
        },
        {
            name: 'Administrator',
            selector: row => <input type="checkbox" readOnly checked={row.administrator} />,
            sortable: true,
            center: 'true',
        },
        {
            name: 'Active',
            selector: row => <input type="checkbox" readOnly checked={row.active} />,
            sortable: true,
            center: 'true',
        },
        {
            button: 'true',
            cell: (row) => (
                <Button variant="warning"
                    onClick={(e) => {
                        handleEditButtonClick(e, row.bleaccountid)
                    }}>
                    Change password
                </Button>
            )
        },
        {
            name: 'id',
            selector: (row) => row.bleaccountid,
            width: '0',
        }

    ];

    useEffect(() => {
        fetchaccounts();
    }, []);

    const fetchaccounts = async () => {
        const response = await axios.get(`https://interceptor.skylab.nl:2807/accounts`);
        setData(response.data);
    };

    const handleEditButtonClick = async (e, id) => {
        //console.log('id:', id)
        setAccountid(id);
        setIsChangePassword(true)
    }

    async function handleUpdatePassword() {
        //console.log(newpasswordRef.current.value, accountid)
        if (newpasswordRef.current.value === '') {
            alert('Please enter a new password')
            return
        }   
        const response = await fetch('https://interceptor.skylab.nl:2807/accountupdatepassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    accountid: accountid,
                    password: newpasswordRef.current.value
                })
        })
            .then(function (response) {
                return response.text();
            })
            .catch(function (error) {
                console.error(error);
            })
    }

    return (
        <>
            <div>
                <DataTable
                    columns={columns_Accounts}
                    data={data}
                ></DataTable>
            </div>
            {IsChangePassword && <div>
                <hr />
                New password
                <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">Change password</InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="Password"
                        aria-label="Password"
                        aria-describedby="basic-addon1"
                        ref={newpasswordRef}
                    />
                </InputGroup>

                <Button className="mb-1"
                onClick={handleUpdatePassword}
                >
                    Save password
                </Button>
            </div>}
        </>
    )
}
