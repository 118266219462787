import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import FormSelect from 'react-bootstrap/FormSelect'
import FloatingLabel from 'react-bootstrap/FloatingLabel';

//http://react-data-table-component.netlify.app/?path=/docs/getting-started-examples--docs
import styled from 'styled-components';
import DataTable from 'react-data-table-component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap } from '@fortawesome/free-regular-svg-icons';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import moment from 'moment';

import "./history.css"

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-color: lightgrey;
	height: 34px;
	width: 64px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color:darkgrey;
	&:hover {
		cursor: pointer;
	}
`;

const TextField = styled.input`
	height: 32px;
	width: 250px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;
	&:hover {
		cursor: pointer;
	}
`;

const Label = styled.label`
	height: 32px;
	width: 90px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	text-align: center;
	padding-top: 9px
`;

const columns_hist = [
	{
		name: 'Date',
		selector: row => moment(row.received).format('HH:mm:ss DD-MM-YY'),
		sortable: 'true',
	},
	{
		name: 'Imei',
		selector: row => row.udi,
		sortable: 'true',
	},
	{
		name: 'Mac',
		selector: row => row.mac,
		sortable: 'true',
	},
	{
		name: 'Name',
		selector: row => row.name,
		sortable: 'true',
	},
	{
		name: 'Manufacturer',
		selector: row => row.manufacturer,
		sortable: 'true',
		button: 'true',
	},
	{
		name: 'Rssi',
		selector: row => row.rssi,
		sortable: 'true',
		center: 'true'
	},
	// {
	// 	name: 'Time',
	// 	selector: row => row.regtime,
	// 	sortable: 'true',
	// 	center: 'true'
	// },
	{
		name: 'Location',
		center: 'true',
		cell: row => (
			<a target="_blank" href={"http://www.google.nl/maps/place/" + row.latitude + "," + row.longitude} rel="noreferrer">
				<FontAwesomeIcon icon={faMap} title="Show on Google map"></FontAwesomeIcon>
			</a>
		),
	}
];

export default function History() {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [options, setOptions] = useState([])
	const [udi, setUdi] = useState([])
	const [showgobutton, setshowGobutton] = useState(false);

	const fetchAccountdevices = async page => {

		setLoading(false);
		const items = JSON.parse(localStorage.getItem('token'));
		
		const { data }  = await axios.post(`https://interceptor.skylab.nl:2807/interceptor`,
		  { accountid: items.id }
		);
		
		const results = []
		data.forEach((value) => {
		  results.push({
			value: value.customerdeviceid,
			label: value.devicename,
			udi: value.udi
		  });
		});
		// Update the options state
		setOptions([
		  { label: 'Select one.'},
		  ...results
		])
		setLoading(false);
	  };

	async function getHistory(information) {
		// //console.log(information)
		return fetch('https://interceptor.skylab.nl:2807/history', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(information)
		})
			.then(data => data.json())
	}

	const handleHistory = async e => {
		e.preventDefault();
		setLoading(true)
		const result = await getHistory({
			udi,
			startDate,
			endDate
		});
		setData(result)
		//console.log(data)
		setLoading(false)
	}

	function handleInterceptionSelect(e) {
		for (let node of e.target.children) {
			if (node.value === e.target.value) {
				//console.log(node.getAttribute('data-id'))
				setUdi(node.getAttribute('data-id'))
				if (node.getAttribute('data-id') !== '*')
					setshowGobutton(true)
				else
					setshowGobutton(false)
			}
		}
	}

	function GenDatePicker() {
		return (
			<>
					<input className='cInput' value="Start date:" readOnly='true' ></input>
					<DatePicker
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						dateFormat="dd-MM-yyyy"
						showWeekNumbers
						maxDate={new Date()}
					/>

					<input className='cInput' value="End date:" readOnly='true'></input>
					<DatePicker
						selected={endDate}
						onChange={(date) => setEndDate(date)}
						dateFormat="dd-MM-yyyy"
						showWeekNumbers
						maxDate={new Date()}
					/>
					{showgobutton && <Button type="button" onClick={handleHistory}>Go</Button>}
			</>
		);
	}

	useEffect(() => {
		fetchAccountdevices();
	}, []);

	const paginationComponentOptions = {
		selectAllRowsItem: 'true',
		selectAllRowsItemText: 'All',
		paginationPerPage: 30
	};
	const fixedHeaderScrollHeight = '1150px'
	const fixedHeader = false;
	const dence = true;

	const FilterComponent_History = ({ filterText, onFilter, onClear }) => (
		<>
			<TextField
				id="search"
				type="text"
				placeholder="Filter on Imei, mac or Name."
				aria-label="Search Input"
				value={filterText}
				onChange={onFilter}
			/>
			<ClearButton type="button" onClick={onClear}>Clear</ClearButton>
		</>
	);

	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const filteredItems = data.filter(
		item =>
			(item.udi && item.udi.toLowerCase().includes(filterText.toLowerCase())) ||
			(item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
			(item.mac && item.mac.toLowerCase().includes(filterText.toLowerCase()))
	);

	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent_History onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

	return (
		<>
			<FloatingLabel className='cSelector' controlId="selectedInterseptor" label="Interceptor:">
				<FormSelect onChange={handleInterceptionSelect}
					name="selectedInterseptor">
					{options.map((option) => (
						<option key={option.value} data-id={option.udi}>{option.udi} - {option.label}</option>
					))}
				</FormSelect>
			</FloatingLabel>

			<GenDatePicker></GenDatePicker>

			<DataTable
				columns={columns_hist}
				data={filteredItems}
				progressPending={loading}
				pagination
				paginationComponentOptions={paginationComponentOptions}
				subHeader
				subHeaderComponent={subHeaderComponentMemo}
				persistTableHead
				highlightOnHover
				fixedHeader={fixedHeader}
				fixedHeaderScrollHeight={fixedHeaderScrollHeight}
				dence={dence}
				paginationPerPage={20}
			>
			</DataTable>
		</>
	);
}
