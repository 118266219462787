import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
//https://react-data-table-component.netlify.app/?path=/docs/getting-started-examples--docs
import DataTable from 'react-data-table-component';

//https://react-bootstrap.netlify.app/docs/forms/input-group
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import './groups.css';

export default function Groups() {
    const [usergroup, setUsergroup] = useState([]);
    const [accountid, setAccountid] = useState();
    const [headertext, setHeadertext] = useState('New group')
    const groupnameRef = useRef('');
    const groupcommentRef = useRef('');
    const groupidRef = useRef();

    const [showAddButton, setShowAddButton] = useState(true);
    const [showUpdButton, setShowUpdButton] = useState(false);

    const columns_Group = [
        {
            name: 'Group',
            selector: row => row.usergroupname,
            sortable: true,
            Alignment: 'left',
        },
        {
            name: 'comment',
            selector: (row) => row.usergroupcomment,
            Alignment: 'left',
        },
        {
            button: 'true',
            cell: (row) => (
                <Button variant="warning"
                    onClick={(e) => {
                        handleEditGroup(row.usergroupid)
                    }}>
                    Edit
                </Button>
            )
        },
        {
            name: 'usergroupid',
            selector: (row) => row.usergroupid,
            width: '0',
        }
    ];

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem('token'));
        if (items) {
            setAccountid(items.id);
        }
        usergroups();
    }, []);

    const usergroups = async () => {
        const response = await axios.get(`https://interceptor.skylab.nl:2807/usergroup`);
        setUsergroup(response.data);
        //console.log(usergroup)
    };

    const handleEditGroup = async (groupid) => {
        //console.log(groupid)
        setHeadertext("Edit group")

        setShowAddButton(false)
        setShowUpdButton(true)

        const response = await fetch('https://interceptor.skylab.nl:2807/getgroup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ groupid: groupid })
        });
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        //console.log('Groupdata:', data)

        groupnameRef.current.value = data[0].usergroupname
        groupcommentRef.current.value = data[0].usergroupcomment
        groupidRef.current.value = data[0].usergroupid

    }

    async function updateGroup(credentials) {

        //console.log('updateGroup credentials:', credentials)

        return fetch('https://interceptor.skylab.nl:2807/updgroup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(function (response) {

                if (response.status === 200) {
                    usergroups();
                    //handleAccountAction('Edit group:' + credentials.account)

                    //Reset Form
                    setHeadertext('Group')
                    setShowAddButton(true)
                    setShowUpdButton(false)
                    groupnameRef.current.value = ''
                    groupcommentRef.current.value = ''
                }
                return response.text();
            })
            .catch(function (error) {
                console.error(error);
            })
    }

    const handleAddGroup = async e => {
        //Check if groupname is empty
        if (groupnameRef.current.value === '') {
            alert('Groupname is empty')
            return
        }
        e.preventDefault();
        await addGroup({
            groupname: groupcommentRef.current.value,
            groupcomment: groupcommentRef.current.value
        });
    }

    async function addGroup(credentials) {

        //console.log('credentials:', credentials)

        return fetch('https://interceptor.skylab.nl:2807/addgroup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(function (response) {

                if (response.status === 200) {
                    usergroups();
                }
                else if (response.status === 599) {
                    //console.log('Group already in db')
                    const confirmBox = window.confirm(
                        'Group already in the list.'
                    )
                }
                return response.text();
            })
            .catch(function (error) {
                console.error(error);
            })
    }

    const handleUpdateGroup = async e => {
        //Check if groupname is empty
        if (groupnameRef.current.value === '') {
            alert('Groupname is empty')
            return
        }   
        e.preventDefault();
        //console.log('handleUpdateGroup')
        await updateGroup({
            groupid: groupidRef.current.value,
            groupname: groupnameRef.current.value,
            groupcomment: groupcommentRef.current.value,
        });
    }

    return (
        <>
            <div className='cForm'>

                <div className='cGroup'>
                    <Form.Label className='cLabel'>{headertext}</Form.Label>
                </div>

                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
                    <Form.Control
                        required={true}
                        placeholder="Group name"
                        aria-label="Group name"
                        aria-describedby="basic-addon1"
                        ref={groupnameRef}
                    />
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">Comment</InputGroup.Text>
                    <Form.Control
                        required={true}
                        placeholder="Comment"
                        aria-label="Comment"
                        aria-describedby="basic-addon1"
                        ref={groupcommentRef}
                    />
                </InputGroup>

                <InputGroup className="mb-3">
                    <Form.Control
                        hidden={true}
                        ref={groupidRef}
                    />
                </InputGroup>
                {showAddButton && <Button onClick={handleAddGroup}>
                    Add
                </Button>
                }
                {showUpdButton && <Button onClick={handleUpdateGroup}>
                    Update
                </Button>
                }
                <hr />
                <div>
                    Overview
                    <DataTable
                        columns={columns_Group}
                        data={usergroup}
                    ></DataTable>
                </div>
            </div>
        </>
    )

}