//https://github.com/ms-aija/LeafletReact5minDemo/blob/master/src/components/Map.js#L22

import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from "axios";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
//https://react-data-table-component.netlify.app/?path=/docs/getting-started-examples--docs
import DataTable from 'react-data-table-component';

import "./maprtnew.css"

import loadlogo from './images/loading.gif';

// --- (6) Create a custom marker ---
const customIcon = new Icon({
  iconUrl: 'https://login.iotdash.nl/images/Markers/Origineel/marker-icon-green.png',
  iconSize: [24, 41],
  popupAnchor: [0, -10]
})

export default function MultiMapRt() {

  const [loading, setLoading] = useState(false);
  const [dataaccInt, setDataaccInt] = useState([]);

  // const [data, setData] = useState([]);

  const columns_interceptor_account = [
    {
      name: 'iMei',
      selector: row => row.udi,
      sortable: true,
      Alignment: 'left',
    },
    {
      name: 'Seal',
      selector: row => row.seal,
      sortable: true,
    
    },
    {
      name: 'Devicename',
      selector: row => row.devicename,
      sortable: true,
    
    },

    {
      name: 'customerdeviceid',
      selector: (row) => row.customerdeviceid,
      width: '0',
    },
    {
      name: 'accountdeviceid',
      selector: (row) => row.accountdeviceid,
      width: '0',
    },
    {
      name: 'bleaccountid',
      selector: (row) => row.bleaccountid,
      width: '0',
    }
  ];
  const columns_rt = [
    // {
    //   name: 'Date',
    //   selector: row => moment(row.received).format('HH:mm:ss DD-MM-YY'),
    //   sortable: 'true',
    //   width: '180px',
    // },
    // {
    //   name: 'Imei',
    //   selector: row => row.udi,
    //   sortable: 'true',
    //   width: '180px',
    // },
    {
      name: 'Mac',
      selector: row => row.mac,
      sortable: 'true',
      width: '140px',
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: 'true',
    },
    {
      name: 'Manufacturer',
      selector: row => row.manufacturer,
      sortable: 'true',
    },
    {
      name: 'Rssi',
      cell: row => row.rssi,
      sortable: 'true',
      center: 'true',
      width: '70px',
      conditionalCellStyles: [
        // You can also pass a callback to style for additional customization
        {
          when: row => row.rssi < 0,
          style: row => ({
            backgroundColor: RSSIIndicator(row.rssi),
          }),
        },
      ]
    },
    {
      name: 'Time',
      selector: row => row.regtime,
      sortable: 'true',
      center: 'true',
      width: '80px',
    },
    {
      name: 'Updated',
      selector: row => moment(row.updatetime).format('HH:mm:ss DD-MM-YY'),
      sortable: 'true',
      center: 'true'
    },
    {
      name: 'Alarm',
      selector: row => row.alarm,
      sortable: 'true',
      conditionalCellStyles: [
        // You can also pass a callback to style for additional customization
        {
          when: row => row.alarm === 1,
          style: row => ({
            backgroundColor: 'orange',
          }),
        },
      ]
    }
  ];

  // Functie om de kleur te interpoleren
  const interpolateColor = (color1, color2, factor = 0.25) => {
    return color1.map((c, i) => Math.round(c + factor * (color2[i] - c)));
  };

  const RSSI_COLORS = [
    { max: -50, color: [0, 255, 0] },        // Green
    { max: -60, color: [173, 255, 47] },     // Green-Yellow
    { max: -70, color: [255, 255, 0] },      // Yellow
    { max: -80, color: [255, 165, 0] },      // Orange
    { max: -90, color: [255, 69, 0] },       // Orange-Red
    { max: Infinity, color: [255, 0, 0] }     // Red
  ];

  // Functie om de RSSI-kleur te verkrijgen
  const getRSSIColor = (rssi) => {
    for (let i = 0; i < RSSI_COLORS.length - 1; i++) {
      if (rssi <= RSSI_COLORS[i].max) {
        let next = RSSI_COLORS[i + 1];
        let factor = (rssi - next.max) / (RSSI_COLORS[i].max - next.max);
        const color = interpolateColor(next.color, RSSI_COLORS[i].color, factor);
        return 'rgb(' + color.join(",") + ')';
      }
    }
    return "rgb(255,0,0)";  // Default color als er geen match is
  };

  // Voorbeeld React component die de kleur op basis van RSSI weergeeft
  function RSSIIndicator(rssi) {
    const color = getRSSIColor(rssi);
    ////console.log(color)
    return color;
  };

  async function getAccountdevices(p_bleaccount) {
    setLoading(true);
    try {
      //console.log('getAccountdevices:', p_bleaccount)
        const response = await fetch('https://interceptor.skylab.nl:2807/interceptor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ accountid: p_bleaccount })
      })
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      //console.log('data:', data)
      setDataaccInt(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('token'));
    getAccountdevices(items.id);
  }, []);

  function MyComponent() {
    const [center, setCenter] = useState([52.1460977688048, 5.450848556067135]);
    const [zoom, setZoom] = useState(6);
    const [centerlat, setCenterlat] = useState(52.1460977688048);
    const [centerlng, setCenterlng] = useState(5.450848556067135);
    const [iconPosition, seticonPosition] = useState([])
    const [devudi, setDevudi] = useState('-')
    const [data, setData] = useState([]);
    const [showmap, setShowmap] = useState(false);

    // Used to recenter the map to new coordinates
    const MapRecenter = ({ lat, lng, zoomLevel }) => {
      const map = useMap();

      useEffect(() => {
        // Fly to that coordinates and set new zoom level
        map.setView([lat, lng], zoomLevel);
      }, [lat, lng]);
      return null;
    };

    async function getHistoryrt(information) {
      const response = await fetch('https://interceptor.skylab.nl:2807/historyrtin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(information)
      })
      const result = await response.json();
      //console.log('result:', result);

      const positions = []

      result.forEach((value) => {
        //console.log(value.latitude, value.longitude, moment(value.received).format('HH:mm:ss DD-MM-YY'))

        const poptxt = 'Date:' + moment(value.updatetime).format('HH:mm:ss DD-MM-YY') + ' iMei:' + value.udi + ' Name:' + value.devicename + ' Fix:' + value.fix

        //console.log('value.udi:', value.udi)

        positions.push({
          lat: value.latitude,
          lng: value.longitude,
          date: moment(value.updatetime).format('HH:mm:ss DD-MM-YY'),
          udi: value.udi,
          name: value.devicename,
          fix: value.fix,
          poptxt: poptxt
        });
      });

      seticonPosition([
        ...positions
      ])

      //console.log('iconPosition:', iconPosition)

      setCenterlat(positions[0].lat)
      setCenterlng(positions[0].lng)

      //console.log('Center map:', centerlat, centerlng)

    }

    const handleChange = ({ selectedRows }) => {
      //console.log('Selected Rows: ', selectedRows);
      if (selectedRows.length > 0) {
        getHistoryrt(selectedRows)
        setShowmap(true)
      }
    };

    const handleMarkerClick = event => {
      //console.log(`Marker data:`, event.target.options.data)
      setDevudi(event.target.options.data)
      fetch_RT(event.target.options.data);
    }

    const fetch_RT = async (imei) => {
      //console.log('fetch_RT:', imei)
      setLoading(false);
      const response = await axios.get('https://interceptor.skylab.nl:2807/data/' + imei);
      //console.log('response imei:', response.data)
      setData(response.data);
      setLoading(false);
    };

    return (
      <>
        <DataTable
          columns={columns_interceptor_account}
          data={dataaccInt}
          selectableRows
          onSelectedRowsChange={handleChange}
        />

        <hr></hr>
        <div className='map'>
          <MapContainer center={center} zoom={zoom} scrollWheelZoom={true}>
            <MapRecenter lat={centerlat} lng={centerlng} zoomLevel={5} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {iconPosition.map((position, i) => (
              < Marker position={position}
                data={position.udi}
                eventHandlers={{ click: (e) => handleMarkerClick(e) }}
                icon={customIcon}
                key={'Marker_' + i}>
                <Popup
                  key={'pop_' + i}>
                  {position.poptxt}
                </Popup>
              </Marker>
            ))}
          </MapContainer>
          <hr></hr>
          <p class="text-left">iMei data:{devudi}</p>
          <DataTable
            columns={columns_rt}
            data={data}
            progressPending={loading}
            pagination
            //paginationComponentOptions={paginationComponentOptions}
            //conditionalRowStyles={conditionalRowStyles_rt}
            //expandableRows expandableRowsComponent={ExpandableRowComponent_RT}
            //subHeader
            //subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            highlightOnHover
            //customStyles={customStyles}
            //fixedHeader={fixedHeader}
            //fixedHeaderScrollHeight={fixedHeaderScrollHeight}
            dence={true}
            paginationPerPage={10}
          />
        </div>
      </>
    );
  };

  //Main return
  return (
    <>
      <div>
        <div className='cFloatLeft'>
            <MyComponent></MyComponent>
          {loading && <div className='cLoading'>
            <p>Loading...
              <img src={loadlogo} height={50} width={50} alt="Loading." />
            </p>
          </div>
          }
        </div>
      </div>
    </>
  )
}

