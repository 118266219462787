//https://github.com/ms-aija/LeafletReact5minDemo/blob/master/src/components/Map.js#L22

import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from "axios";
import moment from 'moment';
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";
import FormSelect from 'react-bootstrap/FormSelect'
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import "./map.css"

import loadlogo from './images/loading.gif';

const position = [51, 4];
// --- (6) Create a custom marker ---
const customIcon = new Icon({
  iconUrl: 'https://login.iotdash.nl/images/Markers/Origineel/marker-icon-green.png',
  iconSize: [24, 41],
  popupAnchor: [0, -10]
})

export default function Map() {

  const [options, setOptions] = useState([])
  const [devudi, setDevudi] = useState('')
  const [iconPosition, seticonPosition] = useState([])
  const [loading, setLoading] = useState(false);

  const [center, setCenter] = useState([52.1460977688048, 5.450848556067135]);
  const [zoom, setZoom] = useState(6);

  const [centerlat, setCenterlat] = useState(52.1460977688048);
  const [centerlng, setCenterlng] = useState(5.450848556067135);

  const [showmap, setShowmap] = useState(false);

  const fetchAccountdevices = async page => {
    setLoading(false);
    const items = JSON.parse(localStorage.getItem('token'));

    const { data } = await axios.post(`https://interceptor.skylab.nl:2807/interceptor`,
      { accountid: items.id }
    );

    const results = []
    data.forEach((value) => {
      results.push({
        value: value.customerdeviceid,
        label: value.devicename,
        udi: value.udi
      });
    });
    // Update the options state
    setOptions([
      { label: 'Select one.', udi: '' },
      ...results
    ])
    setLoading(false);
  };

  async function getHistoryrt(information) {
    return fetch('https://interceptor.skylab.nl:2807/historyrt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(information)
    })
      .then(data => data.json())
  }

  useEffect(() => {
    const timer = setInterval(() => {
      //console.log('Timer.')
      handleHistory()
      setLoading(false)
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, [devudi]);

  const handleHistory = async e => {

    //console.log('handleHistory')
    setLoading(true)
    const result = await getHistoryrt(
      { udi: devudi }
    );

    //console.log('result rt:', result)

    if (result.length === 0) {
      setShowmap(false)
      return;
    }

    const positions = []

    result.forEach((value) => {
      //console.log(value.latitude, value.longitude, moment(value.updatetime).format('HH:mm:ss DD-MM-YY'))
      positions.push({
        lat: value.latitude,
        lng: value.longitude,
        date: moment(value.updatetime).format('HH:mm:ss DD-MM-YY'),
        udi: value.udi,
        name: value.devicename,
        fix: value.fix
      });
    });

    seticonPosition([
      { lat: 0, lng: 0 },
      ...positions
    ])

    setCenterlat(positions[0].lat)
    setCenterlng(positions[0].lng)
    setShowmap(true)
    setLoading(false)
  }


  // Used to recenter the map to new coordinates
  const MapRecenter = ({ lat, lng, zoomLevel }) => {
    const map = useMap();

    useEffect(() => {
      // Fly to that coordinates and set new zoom level
      map.setView([lat, lng], zoomLevel);
    }, [lat, lng]);
    return null;
  };

  const handleInterceptionSelect = (e) => {
    setLoading(true)
    for (let node of e.target.children) {
      if (node.value === e.target.value) {
        const Udi = node.getAttribute('data-id');
        //console.log(Udi)
        setDevudi(Udi); // Update `devudi` state
      }
    }
  };

  useEffect(() => {
    fetchAccountdevices();
  }, []);

  return (
    <>
      <FloatingLabel className='cSelector' controlId="selectedInterseptor" label="Interceptor:">
        <FormSelect onChange={handleInterceptionSelect}
          name="selectedInterseptor"
          id="selInt">
          {options.map((option) => (
            <option key={option.value} data-id={option.udi}>{option.label + ' - ' + option.udi}</option>
          ))}
        </FormSelect>
      </FloatingLabel>

      {loading && <div className='cLoading'>
          <p>Loading...
            <img src={loadlogo} height={50} width={50} alt="Loading" />
          </p>
        </div>
        }

      {showmap && <div className="cMapdiv">
        <section>
          {/* --- Add leaflet map container --- */}
          <div className='map'>
            <MapContainer center={center} zoom={zoom} scrollWheelZoom={true}>
              <MapRecenter lat={centerlat} lng={centerlng} zoomLevel={15} />
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {iconPosition.map((position, i) => (
                < Marker position={position}
                  icon={customIcon}
                  key={'icon_' + i}>
                  <Popup
                    key={'pop_' + i}>
                    {'Date:' + position.date + ' iMei:' + position.udi + ' Name:' + position.name + ' Fix:' + position.fix}
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </div>
        </section >
      </div >}
    </>
  )
}

